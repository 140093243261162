import styles from './LoaderSuspense.module.css';

import logoDefault from '../../../public/loader.svg';

const LoaderSuspense = () => (
    <div className={styles.loaderWrapper} data-locator="loader">
        <img src={logoDefault} className={styles.logo} alt="logo" />
    </div>
);

export default LoaderSuspense;
