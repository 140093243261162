// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..600;1,14..32,100..600&family=Manrope:wght@200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --global-font-family: 'Manrope', sans-serif;
}

html {
    font-family: var(--global-font-family), sans-serif;
    background-color: var(--surface-main);
    font-size: 0;
}

/*todo: add conditional font to a components library*/
html * {
    font-family: var(--global-font-family), sans-serif !important;
}

body,
html,
#app {
    min-height: 100%;
}

#app {
    position: relative;
    font-size: 16px;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Body--checkoutIsOpened {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.mainWrapper {
    min-height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/base.css"],"names":[],"mappings":"AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,kDAAkD;IAClD,qCAAqC;IACrC,YAAY;AAChB;;AAEA,qDAAqD;AACrD;IACI,6DAA6D;AACjE;;AAEA;;;IAGI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..600;1,14..32,100..600&family=Manrope:wght@200..800&display=swap');\n\n:root {\n    --global-font-family: 'Manrope', sans-serif;\n}\n\nhtml {\n    font-family: var(--global-font-family), sans-serif;\n    background-color: var(--surface-main);\n    font-size: 0;\n}\n\n/*todo: add conditional font to a components library*/\nhtml * {\n    font-family: var(--global-font-family), sans-serif !important;\n}\n\nbody,\nhtml,\n#app {\n    min-height: 100%;\n}\n\n#app {\n    position: relative;\n    font-size: 16px;\n}\n\n.ReactModal__Body--open {\n    overflow: hidden;\n}\n\n.ReactModal__Body--checkoutIsOpened {\n    overflow: hidden;\n    position: fixed;\n    width: 100%;\n}\n\n.mainWrapper {\n    min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
